.tp-caption.white_blacktrans, .white_blacktrans {
  font-weight: bold;
  font-size: 50px;
  line-height: 36px;
  font-family: \"Open Sans\", sans-serif;
  padding: 20px;
  border-width: 0px;
  border-style: none;
  color: #fff;
  position: absolute;
  margin: 0px;
  letter-spacing: -1.5px;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.1);
  background: rgba(2, 20, 25, 0.5); }

.tp-caption.blacktrans_background, .blacktrans_background {
  color: #fff;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  padding: 20px;
  border-width: 0px;
  border-style: none;
  border-radius: 0px;
  position: absolute;
  text-shadow: none;
  margin: 0px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: rgba(2, 20, 25, 0.5); }

.tp-caption.white_rokkit, .white_rokkit {
  font-weight: bold;
  font-size: 50px;
  line-height: 36px;
  font-family: \"Open Sans\", sans-serif;
  padding: 0px 4px;
  border-width: 0px;
  border-style: none;
  color: #fff;
  position: absolute;
  padding-top: 1px;
  margin: 0px;
  letter-spacing: -1.5px;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.1); }

.tp-caption.trans_background, .trans_background {
  color: #fff;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  padding: 5px 15px;
  border-width: 0px;
  border-style: none;
  border-radius: 0px;
  position: absolute;
  text-shadow: none;
  margin: 0px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0; }
